import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'gatsby';

const Nav = ({ menu }) => {
  const [menuActive, setActive] = useState(false);
  const [menuClosing, setClosing] = useState(false);

  return (
    <nav className={`app-navigation ${menuActive ? 'active' : ''} ${menuClosing ? 'closing' : ''}`}>
      <button
        className="menu-controls"
        onClick={() => {
          setClosing(menuActive);
          setActive(!menuActive);
        }}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <div className="container">
        {menu.map((item, i) => (
          <Link
            className="nav-link link-container"
            activeClassName="active"
            partiallyActive={item.url !== '/'}
            key={i}
            to={item.url}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </nav>
  );
};

Nav.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default Nav;
