import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Nav from './nav';

import '../styles/contact.scss';
import '../styles/icon.scss';
import '../styles/layout.scss';
import '../styles/nav.scss';
import '../styles/projects.scss';
import '../styles/theatre.scss';
import '../styles/resume.scss';
import '../styles/skills.scss';
import '../styles/work.scss';

// pre-process print-styles to inject into style tag
//  and ensure cascade overrides
import printStyles from '!css-loader!sass-loader!../styles/print.scss';

const Layout = ({
  lang,
  title,
  description,
  meta,
  slug,
  backLink,
  children,
}) => {
  const { settingsYaml: settings } = useStaticQuery(graphql`
    query SettingsQuery {
      settingsYaml {
        siteTitle
        siteDescription
        socialMediaCard {
          image
        }
        twitterAuthor
        googleTrackingId
        menu {
          label
          url
        }
      }
    }
  `);

  let metaTitle = title || settings.siteTitle;
  const metaDescription = description || settings.siteDescription;

  const metaData = !Array.isArray(meta)
    ? Object.entries(meta).map(([key, value]) => {
        if (key === 'title') {
          metaTitle = value;
        }
        return {
          name: key,
          content: value,
        };
      })
    : meta;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s | ${settings.siteTitle}`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          settings.twitterAuthor && {
            name: `twitter:creator`,
            content: settings.twitterAuthor,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .filter(_ => !!_)
          .concat(metaData)}
      >
        <style media="print">{printStyles[0][1]}</style>
      </Helmet>
      <>
        <Nav menu={settings.menu} />
        <main>
          <div className={`container ${slug ? slug : ''}`}>
            {backLink && backLink !== 'pages' ? (
              <Link className="link-back link-container" to={`/${backLink}`}>
                &larr; back to {backLink}
              </Link>
            ) : null}
            {children}
          </div>
        </main>
      </>
    </>
  );
};

Layout.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.object,
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  lang: `en`,
  title: ``,
  description: ``,
  meta: {},
};

export default Layout;
